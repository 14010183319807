import React from 'react';
import { Helmet } from 'react-helmet';
import { InstantSearch } from 'react-instantsearch-dom';
import afostoInstantSearch from '@afosto/instant-search-client';
import Layout from '../../../components/Layout';
import Grid from '../../../components/Grid';
import Typography from '../../../components/Typography';
import Hits from '../../../components/instantSearch/Hits';
import HitsPerPage from '../../../components/instantSearch/HitsPerPage';
import SearchBox from '../../../components/instantSearch/SearchBox';
import RefinementList from '../../../components/instantSearch/RefinementList';
import Stats from '../../../components/instantSearch/Stats';
import Pagination from '../../../components/instantSearch/Pagination';
import ClearRefinements from '../../../components/instantSearch/ClearRefinements';
import CurrentRefinements from '../../../components/instantSearch/CurrentRefinements';
import Breadcrumbs from '../../../components/Breadcrumbs';

const searchProxyID = process.env.GATSBY_SEARCH_PROXY_ID;
const searchIndex = process.env.GATSBY_SEARCH_INDEX;

const localizations = [
  {
    locale: 'nl-NL',
    slug: 'nl/apps/instant-search/demo/',
  },
];

const demo = ({ location }) => {
  const searchClient = afostoInstantSearch(searchProxyID);

  return (
    <Layout location={location} locale="en" localizations={localizations} slug="/apps/instant-search/demo/">
      <Helmet
        title="Instant Search Demo | Afosto"
        meta={[
          {
            name: 'description',
            content: 'Experience lightning-fast search results through this instant search demo.',
          },
        ]}
      >
        <html lang="en" />
      </Helmet>
      <Breadcrumbs location={location} locale="nl" className="mt-24" wrapper={props => <Grid container {...props} />} />
      <Grid container className="pt-16 pb-56">
        <Typography variant="h1" className="mb-16 mt-32" align="center">
          Instant Search demo
        </Typography>
        <Typography
          variant="subtitle-1"
          component="h2"
          weight="normal"
          className="mb-56 mt-16"
          align="center"
          color="text-secondary"
        >
          Try searching through our demo database and experience lightning-fast results as you type!
        </Typography>
        <InstantSearch searchClient={searchClient} indexName={searchIndex}>
          <Grid row direction="row-reverse">
            <Grid column xs={12} md={9}>
              <SearchBox placeholder="Search for 'spider man'" />
              <Stats label="{n} results found" />
              <Hits emptyMessage="No results found for this query" />
              <div className="d-flex justify-content-between align-items-center pt-32">
                <HitsPerPage
                  currentRefinement={10}
                  defaultRefinement={10}
                  items={[
                    { value: 10, label: 'Show 10 results' },
                    { value: 20, label: 'Show 20 results' },
                    { value: 50, label: 'Show 50 results' },
                  ]}
                />
                <Pagination />
              </div>
            </Grid>
            <Grid column xs={12} md={3} className="mt-40 mt-md-0">
              <div className="mb-16 mt-16">
                <CurrentRefinements
                  labelTranslations={{
                    main_genre: 'Genre',
                    release_year_range: 'Release year',
                  }}
                />
              </div>
              <div className="mb-40">
                <ClearRefinements label="Remove all filters" />
              </div>
              <RefinementList attribute="main_genre" title="Genre" />
              <RefinementList attribute="release_year_range" title="Release year" />
            </Grid>
          </Grid>
        </InstantSearch>
      </Grid>
    </Layout>
  );
};

export default demo;
